<template>
  <div id="app">
    <!-- <Header /> -->

    <!-- 根据 showHome 的值渲染不同的页面 -->
    <HomePage v-if="showHome" />
    <OldProjects v-else />

    <!-- <Footer /> -->
  </div>
</template>

<script>
// import Header from './components/Header.vue';
// import Footer from './components/Footer.vue';
import HomePage from './views/HomePage.vue';
import OldProjects from './views/OldProjects.vue';

export default {
  components: {
    // Header,
    // Footer,
    HomePage,
    OldProjects,
  },
  data() {
    return {
      showHome: true,
    };
  },
};
</script>
