<template>
    <div>
      <h1>项目列表</h1>
      <ul>
        <li><a href="/portfolio-2023/index.html" target="_blank">2023个人作品集（英）</a></li>
        <!-- <li><a href="/old-project-2/index.html" target="_blank">项目 2</a></li> -->
        <!-- 可根据需要继续添加 -->
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OldProjects',
  };
</script>
  